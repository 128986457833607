.alert-container {
    
    &__p {
        text-align: justify;
    }

    .action-btn {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0px 0px 0px;

        button {
            margin-left: 8px;
            background: rgba(0, 0, 0, 0.04);
        }

        .button__theme {
            background: var(--theme);
            color: white;
        }
    }
}