.newQuestCont {
    .urlCont {
        padding-top: 8px;
        .label {
            padding-right: 10px;
        }
        .url {
            background: aliceblue;
            color: blue;
            word-break: break-all;
        }
    }

    .badge {
        cursor: pointer;
    }
    .badge:hover {
        transform: scale(1.1);
        font-weight: 900;
    }
}
.loaderCont {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    overflow-y: scroll;
}

.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.question-image{
    width: -webkit-fill-available;
    padding-right: 1rem;
}
.questIndex_container{
    display: flex;
    .questRequired{
        color: #f9602c;
    }
}

.questionText{
    width: 100%;
    word-break: break-word;
}

.tags_view_list {
    padding: 8px 0 0 40px;
}
