.fullscreen-spinner {
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 50vh;
    text-align: center;
    z-index: 100;
  }
  