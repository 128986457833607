.formSect {
    margin: auto;
    max-width: 800px;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.loader {
    position: absolute !important;
    transform: translate(-50%, -50%);
    top: 38% !important;
    left: 50%;
    width: 24px !important;
}

.helperText {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    color: rgb(96, 96, 96);
}

.publishModal {
    .custom-modal-title {
        font-size: 26px;
        text-align: center;
    }
    .alert-container__p {
        text-align: center;
    }
}

.toolbarRight {
    margin-left: auto;
}

.field {
    font-family: "Frank Ruhl Libre";
}

.top-margin {
    margin-top: 10px !important;
}

.select-language{
    min-width: 120px;
    margin-left: 10px;
}

.select-question-type {
    margin: 12px 0;
}

.question-type {
    color: #70757a;
}

.question-type-edit {
    margin: 20px 0;
}

.question-type-view {
    margin: 5px 40px 20px;
}

.dashed-line {
    border-top: 1px dashed #70757a;
}

.star-rating {
    color: #70757a;
    font-size: 30px;
    height: 30px;
    position: relative;
    text-shadow: 0 1px 0 #70757a;
    display: flex;
    flex-direction: column;
    .star-rating-count{
        font-size: 1rem;
        margin-left: 0.5rem;
    }
} 

.star-rating::before { 
    content: '★';
    opacity: .3;
}

.checkBox-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.other-container{
    margin-left: 2rem;
}
.isRequired-radio{
    margin-left: 2rem;

}
.question-type-rating{
    display: flex;
    flex-direction: column;
    .start-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
.required_question{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .upload-image-container{
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
    }
    .question-required{
        display: flex;
        flex-direction: row;
        align-items: center;
     .question-required-switch span{
        color: #0C9785;
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
            background-color: #0C9785;
        }
    }
}

.chip-container {
    padding-top: 10px;
}

