.global_div {
    width: 90%;
    margin: auto;
    display: block;
}

.api-error-message {
    text-align: center;
    padding-top: 50px;
}

.api-error-message-modal, .circularProgress {
    text-align: center;
}

.profile-modal-opacity {
    z-index: 3;
}

.empty-response-message {
    text-align: center;
    padding-top: 50px;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--theme);
    border-radius: 5px;
  }

.search-box {
    border: 1px solid black;
    width: 200px;
    height: 30px;
    display: flex;
    min-width: 180px;
    margin-right: 2%;
    padding-left: 1px;
}

@-moz-document url-prefix(){
    .search-box {
        padding-right: 2px;
   }
 }

.search-box input{
    width: 100%;
    color: black;
    border: none;
    outline: none;
}

.admin_title {
    text-align: center;
    margin-top: 290px;
}

.button_action {
    color: white !important;
    background-color: var(--theme) !important;
}

.disabled-button {
    color: white !important;
    background-color: grey !important;
}

.button_action.disable {
    background-color: grey !important;
}

.adminHead {
    color: black !important
}

.logo {
    text-align: left;
    width:34%;
}

.textAlignment {
    text-align: center;
}

.divAlignment {
    align-items: center;
}


.error_text {
    font-size: 0.75rem;
    color: var(--danger);
    margin-left: 7px;
    margin-top: 6px;
}

.error_text_profile-form {
    font-size: 0.75rem;
    color: var(--danger);
    margin-left: 13px;
    margin-top: -10px;
}

.modal_heading {
    color: grey;
    padding: 5px;
    font-weight: bold;
}

.emptyImage {
    margin-top: 55px;
    width: 100%;
    height: 63vh;
}

.loader { 
    z-index: 5 ;
    position: fixed !important;
    width: 100% !important;
    top: 48px !important;
  }

.display-loader {
    visibility: hidden;
    }

.display-loader.active {
      visibility: visible;
    }

.svg_icons {
    transform: scale(1.5);
}


.profilePic {
    margin: auto 0;
    display: inline-block;
}

.upload-error {
    text-align: right;
    padding: 0 45px 9px 0px;
    font-size: 0.75rem;
    color: var(--danger);
}

.astrick::after {
    content: "*";
    color: var(--danger);
}

  /*---------Styling for triple dots laoder---------*/

  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
}

.loading-dots-profile-form {
    margin-left: 16px;
}

.saving span {
    font: 100 2em/150% Impact;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.saving span:nth-child(2) {
    animation-delay: .2s;
}

.saving span:nth-child(3) {
    animation-delay: .4s;
}

.badge {
    padding: 8px;
    background: #f1f3f4;
    color: #575757;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 500;
    text-transform: capitalize;
    margin-left: auto;
}

.danger {
    color: red;
}

.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ml-auto {
    margin-left: auto !important;
}

.themeBtn {
    background: var(--theme);
    color: white;
}
.option-text{
    width: '75%';
    font-family: 'Frank Ruhl Libre';
    word-break: break-word; 
}
