:root {
  --theme: #FF7D50;
  --danger: #f44336;
}
body {
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: Muli;
}
