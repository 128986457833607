.plain-header {
    padding: 10px 20px;
    display: flex;
    background: rgba(255,255,255,0.8);
    position: fixed;
    width: calc(100% - 40px);
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    background: #fff;
    padding: 8px 24px;
  
    svg:hover {
        transform: scale(1.2);
    }
  
    .left{
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        height: 45px;
        color: #b9352e;
  
        .logo-image{
            width: 90px;
            height: 45px;
        }
  
        .divider-line{
            border-left: 2px solid;
            margin: 0px 10px;
        }
  
        h2 {
            padding: 0px;
            margin: auto;
        }
    }
  
    .right {
        display: flex;
        align-items: center;
    }
  }
  
  *:focus, *:active {
    outline: none;
  }