.login-comp {
  background-size: cover;
  background-position: 50%;
  height: 100vh;
  background-image: url("../../assets/background.svg");
  .main {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 400px;
  }

  .header {
    text-align: center;
  }

  .error {
    text-align: center;
    color: brown;
  }

  .form {
    width: 100%;
    margin-top: 8px;
  }

  .field {
    margin-bottom: 15px !important;
  }

  .submit {
    margin-top: 24px;
  }

  .main {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .paper {
    padding: 16px 24px 24px;
  }

  .header {
    text-align: center;
  }

  .error {
    text-align: center;
    color: brown;
  }

  .form {
    width: 100%;
    margin-top: 8px;
  }

  .field {
    margin-bottom: 15px !important;
  }

  .submit {
    margin-top: 24px;
  }
}
